body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.font-raleway {
  font-family: 'Raleway',sans-serif;
}

.MuiSvgIcon-root {
      fill: currentColor;
      width: 1em;
      height: 1em;
      display: inline-block;
      font-size: 1.5rem;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      flex-shrink: 0;
      user-select: none;
      margin: 0 0 -4px 0px;
      padding-right: 10px;
}


.typist-title{
  margin-bottom: 20px;
}